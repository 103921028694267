#show-data {
  font-size: 13px;
  border: solid 1px;
  border-color: green;
  padding: 5px;
  margin: 1px;
}
button {
  margin-top: 10px;
  margin-bottom: 10px;
}
.MainPage {
  width: 100%;
  overflow: auto;
}
#row1 {
  display: flex;
  position: sticky;
}
h4 {
  font-size: 14px;
}
tr{
 padding: 5px;
}
thead{
  background: white;
  position: sticky;
  top: 0;
}
thead, tbody{
  width: max-content;
}
th, td{
  width: max-content;
}
.Upholstery_data{
  width: -webkit-fill-available;
}
#submitbtn{
  font-size: 23px;
  float: right;
  margin: 4%;
  width: 15%;
  margin-right: 3%;
  border-radius: 8px;
}
.textbox{
  width: 200px;
  height: 100px;
}