.table_data {
  margin: 10px;
  width: 200px;
}
h3 {
  font-size: medium;
  display: none;
}
h1 {
  align-items: center;
 }
h4 {
  font-size: medium;
}
.textbox {
  width: 200px;
  height: 50px;
}
.buton {
  float: right;
  margin-right: 5%;
  background-color: white;
}
.user_Account {
  float: right;
}
.Container {
  margin-top: 10px;
  margin-left: 30px;
  margin-right: 10px;
}
.form_row {
  display: inline;
}
@media screen and (min-width: 415px) {
  .Container {
    flex-direction: column;
  }
}
#Hide {
  display: none;
}
.col {
  width: 10px;
}
.col,
.col-2,
.col-3 {
  font-size: 15px;
  border: solid 1px;
  border-color: white;
}
.row {
  width: 100%;
 }
datalist,
option {
  scroll-behavior: auto;
  overflow-y: auto;
}
.scrollable {
  overflow-y: auto !important;
}
.datalist {
  overflow-y: auto;
}
