.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  backdrop-filter: blur(1px);
}

.box {
  background-color: #c5ffb7;
  padding: 20px;
  color: white;
  border-radius: 40px;
  max-height: 500px;
}
.overlay.show {
  display: block;
}

.overlay:hover {
  cursor: pointer;
}
#downloadbtn{
  float: right;
}

.Total{
  font-size: x-large;
  font-weight: 600;
  text-align:end;
}

.options-container {
  display: flex;
  flex-wrap: wrap;
}

.option-box {
  padding: 8px;
  margin: 8px;
  border: 1px solid #ccc;
}

.Description{
  position: sticky;
}


thead th:first-child,
tbody td:first-child {
  position: sticky;
  left: 0;
    z-index: 1; /* Ensure the frozen column stays on top of other columns */
}
.header_table{
  width: fit-content;
}
#customer_list{
  border: 1px solid black;
  text-align: start;
}
#slection_cust{
text-align: center !important;
}
#shift{
  border: 1px solid black;
  border-radius:8px;
  text-align: start;
  
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loading-dots {
  display: flex;
  align-items: center;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #333; /* You can change the color of the dots here */
  margin: 0 5px;
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}


.loading{
  font-size: 25px;
  font-weight: 500;
  color: black;
  margin-top: 10px;

}

#pivotj .tooltiptext {
  visibility: hidden;
  width: 400px;
  background-color: green !important;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 0;
}

#pivotj:hover .tooltiptext {
  visibility: visible;
}
#title {
  border: 2px solid #007bff;
  padding: 10px;
  display: inline-block;
  border-radius: 5px;
}