#midblock_col_no{
  color: blue;
}
.vaccation_highlight{
  height: auto;
    background: #8DC73F;
    border-radius: 27px;
    padding: 2%;
    min-height: 500px;
}
#vac_highlight_table{
  width: -webkit-fill-available;
  font-size: x-large;
}