.user-name-block {
  background-color: #f0f0f0;
  border: 2px solid #ccc;
  border-radius: 8px;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: inline-block;
}

.user-name {
  font-size: 18px;
  color: #333;
}
