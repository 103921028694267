/* App.css */

body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.ThreelinesApp {
  display: contents;
  height: 100vh;
  overflow: hidden;
}

.hamburger {
  position: relative;
  top: 15px;
  left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 22px;
  cursor: pointer;
  z-index: 1000;
}

.hamburger .line {
  width: 100%;
  height: 4px;
  background-color: #333;
}

.sidebar_hamburger {
  position: fixed;
  right: -250px;
  top: 0;
  width: 250px;
  height: 100%;
  background-color: #f8f9fa;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease;
}

.sidebar_hamburger.open {
  right: 0;
}

.menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-top: 60px;
}

.menu-item {
  margin: 10px 0;
  padding: 10px;
}

.menu-item a {
  color: #333;
  text-decoration: none;
  font-size: 16px;
  display: block;
}

.menu-item a:hover {
  background-color: #e9ecef;
  border-radius: 5px;
}
