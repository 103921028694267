.App {
  text-align: center;
}
.headers{
  width: -webkit-fill-available;
}
.content{
  width: 100%;
}
button{
  float: right;
  margin-right: 3%;
  padding: 5px;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#moldng .tooltiptext {
  visibility: hidden;
  width: 180px;
  background-color: yellow;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}
#moldng:hover .tooltiptext {
  visibility: visible;
}

@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: "Poppins", sans-serif;
}

section {
	display: flex;
	height: 50vh;
	align-items: center;
	justify-content: center;
}

.welcome {
	position: relative;
}

.welcome h2 {
	color: #fff;
	font-size: 15em;
	position: absolute;
	transform: translate(-50%, -50%);
}

.welcome h2:nth-child(1) {
	color: transparent;
	-webkit-text-stroke: 1px #8DC73F;
}

.welcome h2:nth-child(2) {
	color: #8DC73F;
	animation: animate 4s ease-in-out infinite;
}

@keyframes animate {
	0%,
	100% {
		clip-path: polygon(
			0% 45%,
			16% 44%,
			33% 50%,
			54% 60%,
			70% 61%,
			84% 59%,
			100% 52%,
			100% 100%,
			0% 100%
		);
	}

	50% {
		clip-path: polygon(
			0% 60%,
			15% 65%,
			34% 66%,
			51% 62%,
			67% 50%,
			84% 45%,
			100% 46%,
			100% 100%,
			0% 100%
		);
	}
}
