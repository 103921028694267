.datatable-wide_part{
  height: 1800px;
  overflow: auto;
  margin-top: 4%;
}
thead{
  position: sticky;
  top: 0;
  vertical-align: top !important;
}
#Part_dataset_add{
  padding: 2%;
  border: solid;
  border-radius: 23px;
  background: aliceblue;
}