#clm{
  display: contents;

}
#center{
  display: inline-grid;
    margin-top: 5%;

}
thead{
  vertical-align: initial;
}

#logout{
 display: none;
}
.Userdata{
  background: #8dc73f;
  width: 200px;
  height: 200px;
  margin-top: 1%;
  border-radius: 50%;
  display: inline-block;
  align-items: center;
  text-align: center;
}
