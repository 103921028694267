#Area_po_dashboard{
  background-color: #f7f7f7; border-color: #ccc; color: #333; font-size: 16px; padding: 10px; border-radius: 5px;
}

#title_supplychain_box{
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 0;
  margin-top: -4%;
 
}
#supply_chain_title {
    color: hsl(0, 0%, 28%);
    font-size: 120px!important;
    font-weight: bold!important;
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 7px!important;
    cursor: pointer;
    text-transform: uppercase;
     padding: 64px;
    background: linear-gradient(to right, hsl(0, 0%, 30%) 0, hsl(0, 0%, 100%) 10%, hsl(0, 0%, 30%) 20%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shine 3s infinite linear;
  }
  
  @keyframes shine {
    0% {
      background-position: 0px;
    }
    60% {
      background-position: 600px;
    }
    100% {
      background-position: 1500px;
    }
  }
  #user-name-block {
    background-color: #f0f0f0;
    border: 2px solid #ccc;
    border-radius: 8px;
    padding: 10px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: inline-block;
  }
  
  .user-name {
    font-size: 18px;
    color: #333;
  }
  