/* Define styles for alternating rows */
.row.new-group {
  background-color: #f2f2f2; /* Light gray background for new groups */
}

.row.same-group {
  background-color: #ffffff; /* White background for the same group */
}

/* Define the styles for the striped rows */
.row.light {
  background-color: #f2f2f2; /* Light gray background */
}

.row.dark {
  background-color: #ffffff; /* White background */
}
