.headers {
  background-color: #8dc73f;
  display: flex;
  align-items: center;
  top: 0;
  width: 100%;
}
.header_logo_sm {
  width: 50%;
  object-fit: contain;
  margin-top: 7px;
  margin-bottom: 7px;
  margin-left: 20px;
  margin-right: 10px;
}

.header_logo_lg {
  width: 57%;
  object-fit: contain;
  margin-left: -35%;
  margin-top: 5%;
  margin-bottom: 5%;
  

}
.button {
  background-color: green;
  color: white;
  padding-left: 20%;
  padding-right: 20%;
  padding-bottom: 25px;
  padding-top: 25px;
  font-size: 25px;
  border-radius: 66px;
}
.Container {
  margin-top: 10px;
  margin-left: 30px;
  margin-right: 10px;
  display: grid;
}
.body{
width: fit-content;
}
.user-name-block {
  background-color: #f0f0f0;
  border: 2px solid #ccc;
  border-radius: 8px;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: inline-block;
}

.user-name {
  font-size: 18px;
  color: #333;
}
.header-content {
  display: flex;
  justify-content: flex-end; /* Move content to the right */
  align-items: center;
  padding: 10px;
}
