.announcement-box {
  border: 1px solid #ddd;
  height: inherit;
  overflow-x: auto;
  overflow-y: auto;
  font-size: 1.2em;
  padding:3%;
}
.announcement {
  padding-bottom: 20px;
}
#announcement{
  height:100%;
}
#announcement_label{
  font-size: x-large;
  font-weight: 600;
}
#publish{
  font-size: 18px;
  font-weight: bold;
  padding: 12px 24px;
  border-radius: 5px;
  background-color: #007bff;
  color: #fff;
  border: none;
  box-shadow: none;
  transition: all 0.3s ease-in-out;
}
#publish:hover {
  background-color: #0069d9;
  color: #fff;
  border: none;
  box-shadow: none;
}
.news_user{
  float: right;
  text-decoration: solid;
}
.news_details{
  float: left;
}
.each_news{
  background: white;
  border: 1px;
  border-radius: 8px;
  padding: 5%;
  margin: 1%;
}
#attendance_Image{
border-radius: 8%;
width: 60%;
padding: 8%;
}
#announcement_section{
  border: none;
}