#login_container{
  padding: 4%;
}
.gradient-custom-2 {
  /* fallback for old browsers */
  background: #abfc90;
  
  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(to right, #8DC73F, #ffffff, #8DC73F, #ffffff);
  
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+
  background: linear-gradient( #ffffff, #8DC73F,#8DC73F, #ffffff); */
  background: linear-gradient(217deg, #8DC73F, rgba(255,0,0,0) 70.71%),
            linear-gradient(127deg,#8DC73F, rgba(255,0,0,0) 70.71%),
            linear-gradient(336deg, #8DC73F, rgba(255,0,0,0) 70.71%);
  }
  
  @media (min-width: 768px) {
  .gradient-form {
  height: 50vh !important;
  }
  }
  @media (min-width: 769px) {
  .gradient-custom-2 {
  border-top-right-radius: .3rem;
  border-bottom-right-radius: .3rem;
  }
  }
  img{
    width: 75%;
  }
  h4 {
    font-size: 20px;
}

#moldng .tooltiptext {
  visibility: hidden;
  width: 180px;
  background-color: yellow;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}
#moldng:hover .tooltiptext {
  visibility: visible;
}