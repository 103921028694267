.buton {
  background-color: green;
  color: white;
  padding-left: 20%;
  padding-right: 20%;
  padding-bottom: 25px;
  padding-top: 25px;
  font-size: 25px;
  border-radius: 15px;
}
.button:hover{
  font-size: 30px;
}
.button:active{
  background-color: peru;
}
thead{
  background: white;
  position: sticky;
  top: 0;
}
.user_Account {
  float: right;
}
.Container {
  margin-top: 10px;
  margin-left: 30px;
  margin-right: 10px;
  display: grid;
}
.form_row {
  display: inline;
}
@media screen and (min-width: 415px) {
  .Container {
    flex-direction: row;
  }
}
#Hide {
  display: none;
}
.col,
.col-2,
.col-3 {
  font-size: 20px;
  border: solid 1px;
  border-color: white;
}
.column {
  float: left;
  width: 32%;
  padding: 8px;
  
}

/* Clear floats after the columns */

datalist,
option {
  scroll-behavior: auto;
  overflow-y: auto;
}
.scrollable {
  overflow-y: auto !important;
}
.datalist {
  overflow-y: auto;
}
.datatable-wide {
  padding-left: 0;
  padding-right: 0;
}
th#comment{
  width: 10% !important;
}
#moldng .tooltiptext {
  visibility: hidden;
  width: 180px;
  background-color: yellow;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}
#moldng:hover .tooltiptext {
  visibility: visible;
}


.datatable-wide {
  overflow-y: auto; /* Allow vertical scrolling */
max-height: 600px;  
}
.table thead th {
  position: sticky;
  top: 0; /* Stick to the top when scrolling */
  background-color: #f8f9fa; /* Background color for the sticky header */
  z-index: 10; /* Ensure it stays above other rows */
}
