#show-data {
  font-size: 13px;
    border-color: green;
  padding: 5px;
  margin: 1px;
}
button {
  margin-top: 10px;
  margin-bottom: 10px;
}
.MainPage {
  width: 100%;
  overflow: auto;
}
#row1 {
  display: flex;
  position: sticky;
}
h4 {
  font-size: 14px;
}
thead{
  background: white;
  position: sticky;
  vertical-align: initial;
}
#tbody{
  border-top: 0px;
  overflow-y: auto; 
}
.box{
  overflow: auto;
}
.Percentage_bar{
  background-color: bisque;
}