#show-data {
  font-size: 13px;
  border: solid 1px;
  border-color: green;
  padding: 5px;
  margin: 1px;
}
button {
  margin-top: 10px;
  margin-bottom: 10px;
}
.MainPage {
  width: 100%;
  overflow: auto;
}
#row1 {
  display: flex;
  position: sticky;
}
h4 {
  font-size: 14px;
}
thead{
  background: white;
  position: sticky;
  top: 0;
  vertical-align: initial;
}
th#comment{
  width: 10% !important;
}
#moldng .tooltiptext {
  visibility: hidden;
  width: 180px;
  background-color: yellow;
  color: black;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}
#moldng:hover .tooltiptext {
  visibility: visible;
}
#btn_type{
  padding-top: 2px;
  padding-bottom: 2px;
}