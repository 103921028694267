#selected_shift{
  font-size: larger;
}
#attendance_table{
  font-size: 20px;
}
.attendance_table_head{
  z-index: 100;
}
#title{
  font-size: 20px;
}
#enter_vaccation{
  background: lightgray;
  display: inline;
}
.block_user{
  background-color: #f0f0f0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}
