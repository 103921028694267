#threeLines{
 visibility: hidden;
 height: 0;
}

#header-row-production {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#sidebar-col {
  display: flex;
  justify-content: center;
  padding: 10px;
}

#sidebar-nav {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

#user-info-col {
  text-align: right;
  padding-right: 10px;
}

#user-name-block {
  display: inline-block;
}

#all-report-section{
  width: 200%;
  margin-left: -45%;
}

/*cod for specially schedule*/
#filter-row .form-rounded {
  border-radius: 20px;
}

/*for making red color of row*/
#HistoryRRM , #RRM{
  --bs-table-bg:none;
}

#RRM_table{
  max-height: 700px;
}
.highlight-row {
  border-radius: 10px;
  border: none;
  background-color: red !important;
}

@media only screen and (max-width: 767px) {
  #filter-row .mb-3 {
    margin-bottom: 15px;
  }

  #filter-row .text-center {
    text-align: center;
  }

  #filter-row .btn-block {
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  #filter-row .form-control {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }

  #filter-row .text-center {
    text-align: right;
  }
}

.MainPage_data{
  overflow-x: auto;
}

#Upholstery_Schedule_table{
  overflow-x: auto;
}
#Upholstery_Schedule_table table thead th{
  background-color: lightgrey;
}

.red-background {
  border-radius: 10px;
  border: none;
  background-color: rgba(255, 54, 54, 0.856) !important;
}

.orange-background {
  border-radius: 10px;
  border: none;
  background-color: orange !important;
}

.green-background {
  border-radius: 10px;
  border: none;
  background-color: rgb(116, 255, 139) !important;
}

.white-background {
  border-radius: 10px;
  border: none;
  background-color: white !important;
}

.lightgrey-background {
  border-radius: 10px;
  border: none;
  background-color: lightgrey !important;
}
input.lightgrey-background:disabled {
  border-radius: 10px;
  border: none;
  background-color: rgb(97, 97, 97) !important;
  color: transparent;
}


#Upholstery_Schedule_table {
  overflow-y: auto; /* Allow vertical scrolling */
max-height: 800px;  
}
.table thead th {
  position: sticky;
  top: 0; /* Stick to the top when scrolling */
  background-color: #f8f9fa; /* Background color for the sticky header */
  z-index: 1; /* Ensure it stays above other rows */
}
#alldata{
  overflow-y: auto;
  width: 101%;
  max-height: 600px;
}
.pivot_table_section{
  overflow-y: auto;
    width: 101%;
    max-height: 600px;
}
#alldata_upholsterydataset{
  width: 150%;
}

@media only screen and (min-width: 280px) and (max-width: 900px) {


#mobileView{
display: none;
}
h2{
  font-size: 12px;
}
#mobiletext{
  font-size: 12px;
}
#col-2{
  width: 20%;
}
#col-10{
  width: 80%;
}
#btn{
  font-size: medium !important;
  padding: 0%;
  border-radius: 7px !important;
}
#alldata{
/*overflow-x: auto;*/
}
/* For open orders*/
.responsive-form-group {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 20px;
  margin-left: 30px;
}

.responsive-form-group .form-control {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.responsive-form-group .btn {
  width: 30%;
}
.responsive-form-group .col {
  flex: 1;
  min-width: calc(100% / 2 - 15px);
}
#openorder_box{
  display: contents;
}
#alldata_openorder{
  overflow-x: auto;
}
#attendance_dashboard{
  display: flex;
  margin-left: 17%;
  
}
#Attendance_filters{
  display: flex;
}
.vaccation_highlight{
min-height: auto !important;
}
#vac_highlight_table{
  font-size:  small !important;
}
#attendance_details{
  display: contents;
}

#Attendance_edit{
  display: flex;
}
#add_vacation_table{
  font-size: smaller;
  min-height: auto;
  width: -webkit-fill-available;
  margin-bottom: 42px;
}
#add_new_vacation{
  height: fit-content !important;
  margin: auto;
}
#threeLines{
  visibility: visible;
  height: 0;
 }

#openorder_filter{
  overflow-x: visible;
}
.logout_button{
  position: static !important;
}
/*For production page*/
#header-row-production {
  flex-direction: column;
  align-items: center;
}

#sidebar-col {
  width: 100%;
  padding: 5px;
}

#sidebar-nav {
  flex-direction: column;
  align-items: center;
}

#user-info-col {
  width: 100%;
  text-align: center;
  padding-right: 0;
  margin-top: 10px;
}
#nav-item-1{
  padding: 10px 150px !important;
  margin-top: 10%;
  margin-bottom: 2%;
}
#nav-item-2,#nav-item-3{
  padding: 10px 150px !important;
  margin-bottom: 2%;
}
#title_supplychain_box{
  display: none !important;
}
#user-name-block{
  display: none !important;
}
#all-report-section{
  width: 190%;
  margin-left: 1px;
 }
#new-shift-report-block{
  width: 175%;
}
/*attendance page*/
#attendance_table{
  font-size: smaller !important;
}
/*code for scheduling*/
#scheduling-whole-block{
  overflow-x: auto;
}
#filter-row{
 margin: 1%;
}
#page-title{
  font-size: large;
}
.area-title-production{
  margin-left: 69%;
}
#supplychain-sidebar{
  width: 200%;
}
.nav-item span{
  font-size: smaller !important;
}
#supplychain-button{
  width: 25% !important;
}
#supply-section{
  width: 100% !important;
}
/* supervisor dashboard*/
#cus_btn{
  height: auto !important;
}
/* Supply chain dashboard*/

#Supply_chain_title{
  width: inherit;
}
#openorder-button{
  width: 56% !important;
}
#Openorder-option{
  width: 40%;
}
#Bom-filter{
  display: inline-flex;
}

#Bom-filter td{
  width: 30% !important;
}
#Bom_page{
  overflow-x: auto;
}
#shift_report_block{
  display: flow-root !important;
}
.welcome h2 {
  font-size: 5em;
}
#new_shift-heading{
  font-size: medium !important;
}




  .Container{
    display: block;
  }
  .row{
    display: flex;
    overflow-x: auto;
  }
  .row>* {
    flex-shrink: 0;
    margin-top: var(--bs-gutter-y);
    max-width: 100%;
    padding-left:1%;
    padding-right:0;
    width: auto;
}
  .col, .col-2, .col-3, .col-5, .col-sm {
    width: 60% !important;
    padding-left: 1%;
  }
.col{
  flex:none;
}
#hiden{
  display: none;
}
#date{
  width: 238%;
}
}
