#shift_report_block{
  display: flex;
}
#new_shift_report_block{
  display: inline;
}
#shift_section_editor{
  background-color: darkgrey;
}   
.tukdd6b{
  height:1%;
  }
