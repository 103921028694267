/* Sidebar.css */


.sidebar {
  width: 102%;
   background-color: #2B6532;
  color: #ecf0f1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.sidebar-header {
  text-align: center;
  margin-bottom: 30px;
}

.sidebar-header .logo {
  background-color: #2980b9;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.sidebar-header h3 {
  margin: 0;
  font-size: 1.2em;
  color: #ecf0f1;
}

.sidebar-header p {
  margin: 0;
  font-size: 0.9em;
  color: #bdc3c7;
}

.sidebar-nav {
  list-style: none;
  padding: 0;
  width: 100%;
  display: inline-flex;
}

.nav-item {
  width: 100%;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;
  color: #ecf0f1;
  text-decoration: none;
  border-radius: 38px;
}

.nav-item:hover {
  background-color: #8dc73f;
}

.nav-item .icon {
  margin-right: 10px;
  font-size: 1.2em;
}

.nav-item span {
  font-size: 1em;
  padding-left: 15%;
}

.content-area {
  flex: 1;
  padding: 20px;
}