/* Footer.css */

/* Base styles */
.footer {
  background-color: #8DC73F;
  color: black;
  font-size: larger;
  padding: 20px 0;
  bottom: 0;
  width: 100%;
}

.footer h6 {
  font-size: 1rem;
  font-weight: bold;
}

.footer p, .footer a {
  font-size: 0.9rem;
  text-decoration: none;
  color: black;
}

.footer img {
  max-height: 50px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .footer {
    text-align: center;
    font-size: medium;
  }

  .footer h6, .footer p, .footer a {
    font-size: 0.8rem;
  }

  .footer .text-center {
    padding: 10px 15px;
  }

  .footer img {
    margin-bottom: 10px;
  }

  .footer .mb-3 {
    margin-bottom: 15px !important;
  }
}

@media (max-width: 576px) {
  .footer {
    font-size: smaller;
    height: auto;
  }

  .footer h6, .footer p, .footer a {
    font-size: 0.75rem;
  }

  .footer img {
    max-height: 40px;
  }

  .footer .mb-3 {
    margin-bottom: 10px !important;
  }
}
