/* VacationForm.css */

.vacation-form-container {
  max-width: 80%;
  margin: 10px auto;
}

h2 {
  text-align: center;
  color: #333;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin: 10px 0;
}

select,
input,
textarea {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
}

button {
  background-color: #4caf50;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
}

button:hover {
  background-color: #45a049;
}
#vacation_table{
  padding-top: 4%;
}
#add_vacation_table{
  border: solid;
  padding: 3%;
  height: 800px;
  overflow: auto;
    border-radius: 40px;
}