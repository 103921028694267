/* Style the block */
.block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  margin-bottom: 10px;
    padding: 1rem;
  height: 10rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
}

.block:hover {
  transform: translateY(-0.25rem);
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.2);
}

.block__icon {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.block__title {
  font-size: 1.25rem;
  font-weight: bold;
  text-align: center;
}

.bg-primary {
  background-color: blue;
}

.bg-success {
  background-color: green;
}

.bg-danger {
  background-color: red;
}

.bg-warning {
  background-color: yellow;
}

.dashboard_body{
  background-color: #ffffff;
  background-image: linear-gradient(0deg, #ffffff 0%, #8dc73f 100%);
}
#block-1,#block2,#block-3{
  padding-inline: 1%;
  border: none;
}
#today_shipment{
  width: auto;
}
#cus_btn {
  font-size: x-large;
  padding: 5%;
  height: 70px;
  margin: 10px;
  border-radius: 8px;
  line-height: 1.2;
  width: 100%;
}

.table-container {
  width: 100%;
  overflow-x: auto;
}

.table {
  border-collapse: collapse;
  width: 100%;
}

.table_td {
  padding: 10px;
  width: calc(100% / 3);
  background: none;
}
/* Media query for smaller screens */
@media (max-width: 768px) {
  .block {
    height: 8rem; /* Adjust height for smaller screens */
    padding: 0.5rem; /* Adjust padding for smaller screens */
  }
}

/* Media query for even smaller screens */
@media (max-width: 576px) {
  .block {
    height: 6rem; /* Further adjust height for even smaller screens */
    padding: 0.25rem; /* Further adjust padding for even smaller screens */
  }
}
@media only screen and (min-width: 280px) and (max-width: 900px) {
  #logout_button{
    position: static !important;
  }
  
}